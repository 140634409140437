<template>
  <div class="query-container" :class="{ opend: opend }">
    <div class="query-header">
      <i :class="opend ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" @click="opend = !opend"></i>
      {{ data.index + 1 }} PromQL
    </div>

    <div class="query-content">
      <el-row :gutter="20">
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-autocomplete
            v-model="item.query"
            :fetch-suggestions="querySearch"
            highlight-first-item
            size="small"
            style="width: 100%;"
            @select="handleSelect"
            @change="change('update')"
          >
            <template slot="prepend">
              {{ $t("panelQuery") }}
            </template>
            <template slot-scope="{ item }">
              <div>{{ item }}</div>
            </template>

            <template slot="append">
              <a href="javascript:;" style="font-size:12px" @click="dialogVisible = true">
                {{ $t("promQLQuickGenerate") }}
              </a>
            </template>
          </el-autocomplete>
        </el-col>

        <el-col :span="9">
          <el-input v-model="item.legend" size="small" @change="change('update')">
            <template slot="prepend">
              {{ $t("legendFormat") }}
              <el-tooltip effect="dark" :content="$t('legendFormatTooltipText')" placement="bottom">
                <span style="cursor: pointer;"><i class="el-icon-info"></i></span>
              </el-tooltip>
            </template>
          </el-input>
        </el-col>

        <el-col :span="6">
          <!-- <el-button size="small" icon="el-icon-data-line" @click="change('update')">更新</el-button> -->
          <el-button size="small" icon="el-icon-delete" @click="change('delete')">
            {{ $t("handle.delete") }}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-dialog :title="$t('promQLGenerator')" :visible.sync="dialogVisible" top="30px">
      <el-form :model="qlForm" size="small" label-position="top">
        <el-form-item :label="$t('promQLSelectMetric')">
          <el-select v-model="qlForm.metric" style="width: 100%;" filterable>
            <el-option v-for="(item, index) in values" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('promQLAggrFn')">
          <el-select v-model="qlForm.fun" style="width: 100%;" filterable clearable>
            <el-option value="sum">
              <div>
                <span style="float: left">sum </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.sum") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="count">
              <div>
                <span style="float: left">count </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.count") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="rate">
              <div>
                <span style="float: left">rate </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.rate") }}
                  <el-tag size="mini" type="success">
                    {{ $t("intervalValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="irate">
              <div>
                <span style="float: left">irate </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.irate") }}
                  <el-tag size="mini" type="success">
                    {{ $t("intervalValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="increase">
              <div>
                <span style="float: left">increase </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.increase") }}
                  <el-tag size="mini" type="success">
                    {{ $t("intervalValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="min">
              <div>
                <span style="float: left">min </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.min") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="max">
              <div>
                <span style="float: left">max </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.max") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="avg">
              <div>
                <span style="float: left">avg </span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.avg") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="topk">
              <div>
                <span style="float: left">topk</span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.topk") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>

            <el-option value="bottomk">
              <div>
                <span style="float: left">bottomk</span>
                <span style="float: right; color: #8492a6; font-size: 12px">
                  {{ $t("aggrFn.bottomk") }}
                  <el-tag size="mini">
                    {{ $t("instantValue") }}
                  </el-tag>
                </span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('promQLAggrBy')" v-if="['sum', 'count', 'avg'].includes(qlForm.fun)">
          <el-switch v-model="qlForm.aggrSwitch" style="margin-bottom: 6px;"> </el-switch>

          <div v-if="qlForm.aggrSwitch">
            <el-input v-model="qlForm.aggrStr">
              <el-select v-model="qlForm.aggr" slot="prepend" style="width: 100px;">
                <el-option label="by" value="by"></el-option>
                <el-option label="without" value="without"></el-option>
              </el-select>
            </el-input>

            <div class="help">
              {{ $t("promQLAggrTips1") }}
            </div>

            <div class="help">
              {{ $t("promQLAggrTips2") }}
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('promQLTimerange')" v-if="['increase', 'rate', 'irate'].includes(qlForm.fun)">
          <el-input v-model="qlForm.timerange">
            <el-select v-model="qlForm.timeunit" slot="append" style="width: 100px;">
              <el-option label="seconds" value="s"></el-option>
              <el-option label="minutes" value="m"></el-option>
              <el-option label="hours" value="h"></el-option>
              <el-option label="days" value="d"></el-option>
              <el-option label="weeks" value="w"></el-option>
              <el-option label="years" value="y"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('promQLKValue')" v-if="['topk', 'bottomk'].includes(qlForm.fun)">
          <el-input v-model="qlForm.kvalue"> </el-input>
        </el-form-item>

        <el-form-item :label="$t('promQLLabelFilter')">
          <div v-for="(item, index) in qlForm.labels" :key="index" style="margin-bottom: 10px;">
            <el-row :gutter="20">
              <el-col :span="9">
                <el-input v-model="item.key" size="small">
                  <template slot="prepend">
                    Key
                  </template>
                </el-input>
              </el-col>

              <el-col :span="4">
                <el-select v-model="item.exp" size="small" style="width: 100%;">
                  <el-option label="=" value="=">
                    <div style="min-width: 120px;">
                      <span style="float: left">=</span>
                      <span style="float: right; color: #8492a6; font-size: 12px">
                        {{ $t("promQLExpression.equal") }}
                      </span>
                    </div>
                  </el-option>
                  <el-option label="!=" value="!=">
                    <div>
                      <span style="float: left">!=</span>
                      <span style="float: right; color: #8492a6; font-size: 12px">
                        {{ $t("promQLExpression.notEqual") }}
                      </span>
                    </div>
                  </el-option>
                  <el-option label="=~" value="=~">
                    <div>
                      <span style="float: left">=~</span>
                      <span style="float: right; color: #8492a6; font-size: 12px">
                        {{ $t("promQLExpression.regular") }}
                      </span>
                    </div>
                  </el-option>
                </el-select>
              </el-col>

              <el-col :span="9">
                <el-input v-model="item.value" size="small">
                  <template slot="prepend">
                    Value
                  </template>
                </el-input>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="qlForm.labels.splice(index, 1)"
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </div>

          <el-button
            type="default"
            size="small"
            plain
            icon="plus"
            @click="qlForm.labels.push({ key: '', exp: '=', value: '' })"
          >
            + {{ $t("promQLAddLabel") }}
          </el-button>

          <div class="help">
            {{ $t("promQLLabelTips1") }}
          </div>

          <div class="help">
            {{ $t("promQLLabelTips2") }}
          </div>
        </el-form-item>
      </el-form>

      <div class="generate-promql-container" v-if="generateFlag">
        <div class="desc">PromQL:</div>

        <div class="title">
          {{ promQLString }}
        </div>

        <div style="margin-top: 10px;">
          <el-button size="mini" type="primary" v-clipboard:copy="promQLString">
            <i class="el-icon-document-copy"></i>
            {{ $t("handle.copy") }}
          </el-button>
        </div>
      </div>

      <span slot="footer">
        <el-button type="primary" size="small" @click="generateQL">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep, includes } from "lodash";

export default {
  props: {
    data: Object,
    values: Array
  },

  data() {
    return {
      opend: true,
      item: {},
      dialogVisible: false,
      qlForm: {
        metric: "",
        labels: [],
        fun: "",
        timerange: "",
        timeunit: "m",
        aggrStr: "",
        aggr: "by",
        aggrSwitch: true,
        kvalue: ""
      },

      promQLString: "",
      generateFlag: false
    };
  },

  methods: {
    change(type) {
      this.$emit("change", {
        item: this.item,
        index: this.data.index,
        type
      });
    },

    handleSelect(item) {
      this.$set(this.item, "query", item);
      this.change("update");
    },

    querySearch(queryString, cb) {
      var results = queryString
        ? this.values.filter(item => {
            return includes(item, queryString);
          })
        : this.values;
      cb(results);
    },

    generateQL() {
      this.generateFlag = false;

      this.promQLString = this.qlForm.metric;

      let labelQuery = "";
      if (this.qlForm.labels.length > 0) {
        let labelQueryArr = this.qlForm.labels.map(item => {
          return `${item.key}${item.exp}"${item.value}"`;
        });

        labelQuery = `{${labelQueryArr.join(",")}}`;
        this.promQLString = `${this.qlForm.metric}${labelQuery}`;
      }

      if (this.qlForm.fun) {
        let aggrStr = this.qlForm.aggrSwitch ? `${this.qlForm.aggr} (${this.qlForm.aggrStr})` : "";
        switch (this.qlForm.fun) {
          case "sum":
          case "count":
          case "avg":
            this.promQLString = `${this.qlForm.fun}(${this.promQLString}) ${aggrStr}`;
            break;

          case "rate":
          case "irate":
          case "increase":
            this.promQLString = `${this.qlForm.fun}(${this.promQLString}[${this.qlForm.timerange}${this.qlForm.timeunit}])`;
            break;

          case "min":
          case "max":
            this.promQLString = `${this.qlForm.fun}(${this.promQLString})`;
            break;

          case "topk":
          case "bottomk":
            this.promQLString = `${this.qlForm.fun}(${this.qlForm.kvalue}, ${this.promQLString})`;
            break;
        }
      }

      this.$nextTick(() => {
        this.generateFlag = true;
        this.item.query = this.promQLString;
        this.change("update");
        this.dialogVisible = false;
      });
    }
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    }
  },

  mounted() {
    this.item = cloneDeep(this.data.item);
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.query-container {
  background-color: #fff;
  box-shadow: $common-shadow;
  border: 1px solid $border-color;
  margin-bottom: 10px;
  border-radius: 8px;

  &.opend {
    height: auto;
  }

  height: 40px;
  overflow: hidden;
}

.query-header {
  background-color: #fafbfc;
  padding: 4px 30px;
  color: $color-main;
  border-radius: 2px;
  line-height: 32px;
  font-size: 14px;

  position: relative;

  i {
    font-size: 16px;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.query-content {
  padding: 20px;

  .el-input-group--prepend .el-select.item-query-select .el-input.is-focus .el-input__inner {
    border-color: #409eff;
  }

  .item-query-select {
    width: 100%;
  }
}
</style>
