<template>
  <div class="metric-wrapper">
    <div class="metric-header">
      <div class="back-container">
        <svg-icon icon-class="back" class-name="back" @click.native="$router.go(-1)"></svg-icon>
        {{ $t("editPanel") }}
      </div>

      <el-button type="primary" size="mini" @click="submit">
        {{ $t("handle.submit") }}
      </el-button>
    </div>

    <div v-loading="loading" class="metric-content-container">
      <el-row :gutter="20" v-if="!loading">
        <el-col :span="18">
          <div class="metric-chart-wrapper">
            <div class="list-title">{{ metricInfo.title }}</div>

            <chart-tool
              :visibleObj="{ timeRange: true, interval: false, addChart: false }"
              :timeRange.sync="timeRange"
              @timeRangeChange="timeRangeChange"
              class="chart-tool"
            />

            <div class="metric-chart-container">
              <chart-panel :timeRange="timeRange" ref="chart-panel" :metric-info="metricInfo" />
            </div>
          </div>

          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="Query" name="Query">
              <div class="promql-query-container">
                <div class="select-provider">
                  <span class="select-provider-text">{{ $t("selectPrometheusOrigin") }}：</span>

                  <el-select v-model="metricInfo.provider" size="small" @change="providerChange">
                    <el-option
                      v-for="item in providers"
                      :key="item.provideruuid"
                      :label="item.cluster"
                      :value="item.provideruuid"
                      :disabled="item.status == 'Deny'"
                    >
                      <span style="float: left">{{ item.cluster }}</span>

                      <span style="float: right;" class="status error" v-if="item.status === 'Deny'">
                        {{ $t("effectDeny") }}
                      </span>
                      <span style="float: right;" class="status success" v-else>
                        {{ $t("effectAllow") }}
                      </span>
                    </el-option>
                  </el-select>
                </div>
              </div>

              <query-item
                v-for="(item, index) in metricInfo.panelQLs"
                :data="{ item, index }"
                :values="metricsValueList"
                :key="index"
                @change="quertItemChange"
              ></query-item>

              <el-button size="small" icon="el-icon-plus" @click="addQueryItem">
                {{ $t("addQuery") }}
              </el-button>
            </el-tab-pane>
          </el-tabs>
        </el-col>

        <el-col :span="6">
          <el-tabs type="card">
            <el-tab-pane :label="$t('panelSetting')">
              <el-collapse v-model="collapseName" class="chart-conf-collapse">
                <el-collapse-item :title="$t('panelTitleSetting')" name="Setting">
                  <el-form size="small" label-position="top">
                    <el-form-item :label="$t('panelTitle')">
                      <el-input v-model="metricInfo.title"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('panelDescription')">
                      <el-input v-model="metricInfo.description" type="textarea"></el-input>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>

                <el-collapse-item :title="$t('panelVisualization')" name="Visualization">
                  <el-row :gutter="20">
                    <el-col :span="8" v-for="item in visualizationList" :key="item.title" style="margin-bottom: 10px;">
                      <div
                        class="chart-type-item"
                        :class="{ active: item.title === metricInfo.chartType }"
                        @click="switchChartType(item.title)"
                      >
                        <div class="chart-type-item-title">{{ item.title }}</div>
                        <img :src="item.image" />
                      </div>
                    </el-col>
                  </el-row>
                </el-collapse-item>

                <el-collapse-item :title="$t('panelDisplay')" name="Display">
                  <el-form :form="metricInfo.chartConfig" size="small" label-position="top">
                    <el-form-item :label="$t('openArea')">
                      <el-switch v-model="metricInfo.chartConfig.showArea"> </el-switch>
                    </el-form-item>

                    <!-- <el-form-item label="图例位置">
                      <el-radio-group v-model="metricInfo.chartConfig.legendPosition" size="small">
                        <el-radio-button label="bottom">Bottom</el-radio-button>
                        <el-radio-button label="right">Right</el-radio-button>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item label="警告值">
                      <el-input v-model.number="metricInfo.chartConfig.warningValue"> </el-input>
                    </el-form-item>

                    <el-form-item label="危险值">
                      <el-input v-model.number="metricInfo.chartConfig.dangerValue"> </el-input>
                    </el-form-item> -->
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane :label="$t('panelThreshold')">
              <el-collapse v-model="thresholdCollapseName" class="chart-conf-collapse">
                <el-collapse-item :title="$t('threshold')" name="Threshold">
                  <el-form :form="metricInfo.chartConfig" size="small" label-position="top">
                    <el-form-item :label="$t('panelDangerValue')">
                      <el-input v-model.number="metricInfo.chartConfig.dangerValue"> </el-input>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import QueryItem from "./QueryItem";
import ChartTool from "@/components/ChartTool";
import chartTool from "@/mixins/chart-tool";
import ChartPanel from "@/pages/Detail/components/ChartPanel";

import Bar from "@/assets/chart/Bar.svg";
import Text from "@/assets/chart/Text.svg";
import Line from "@/assets/chart/Line.svg";
// import Table from "@/assets/chart/Table.svg";

import { cloneDeep, merge } from "lodash";
import { createPanel, updatePanel, getPanel } from "api/dashboard";
import { CHART_CONFIG } from "utils/constant";
import { metricsValue } from "api/monitor";

export default {
  components: { QueryItem, ChartTool, ChartPanel },
  mixins: [chartTool],

  data() {
    return {
      visualizationList: [
        {
          title: "Line",
          image: Line
        },
        {
          title: "Bar",
          image: Bar
        },
        // {
        //   title: "Table",
        //   image: Table
        // },
        {
          title: "Text",
          image: Text
        }
      ],

      metricInfo: {
        organizationUUID: "",
        dashboardUUID: "",
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        title: "Panel Title",
        description: "",
        chartType: "Line",
        chartConfig: CHART_CONFIG,
        provider: "",
        vendor: "",
        region: "",
        cluster: "",
        panelQLs: [
          {
            // query: "",
            query: "",
            // "kube_pod_container_resource_limits_cpu_cores{container='app-busybox',pod='apply-1-1611712800-zq28t',namespace='yang'}",
            // "sum (container_memory_working_set_bytes{container='',namespace='kube-system'}) by (namespace,pod) / 1024 / 1024",
            legend: "",
            queryType: ""
          }
        ]
      },

      activeName: "Query",
      collapseName: ["Setting", "Visualization", "Display"],
      thresholdCollapseName: ["Threshold"],
      loading: true,
      metricsValueList: []
    };
  },

  methods: {
    timeRangeChange() {},

    switchChartType(type) {
      this.metricInfo.chartType = type;
    },

    quertItemChange(data) {
      let { item, index, type } = cloneDeep(data);
      type === "delete" ? this.metricInfo.panelQLs.splice(index, 1) : this.metricInfo.panelQLs.splice(index, 1, item);
    },

    addQueryItem() {
      this.metricInfo.panelQLs.push({
        query: "",
        legend: "",
        queryType: ""
      });
    },

    async getMetricsValue() {
      let response = await metricsValue(
        {
          vendor: this.metricInfo.vendor,
          region: this.metricInfo.region,
          cluster: this.metricInfo.cluster
        },
        {
          "X-Kubestar-Clusterids": this.metricInfo.provider
        }
      );

      if (response.code === 0) {
        let rep = JSON.parse(response.data);
        rep.status === "success" ? (this.metricsValueList = rep.data) : console.error(rep.error);
      }
    },

    providerChange(value) {
      for (let item of this.providers) {
        if (item.provideruuid === value) {
          this.metricInfo.vendor = item.kind;
          this.metricInfo.region = item.regionID;
          this.metricInfo.cluster = item.cluster;

          this.getMetricsValue();
          break;
        }
      }
    },

    getMetricInfo() {
      this.loading = true;

      getPanel(this.dashboard, this.panel).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.metricInfo = merge({}, { chartConfig: CHART_CONFIG }, cloneDeep(response.data));

          for (let item of this.providers) {
            if (
              item.kind == this.metricInfo.vendor &&
              item.regionID == this.metricInfo.region &&
              item.cluster == this.metricInfo.cluster &&
              item.status != "Deny"
            ) {
              this.getMetricsValue();
              break;
            }
          }
        }
      });
    },

    submit() {
      let action = this.type === "add" ? createPanel : updatePanel;

      action(this.dashboard, this.metricInfo).then(response => {
        if (response.code === 0) {
          this.$router.push({
            path: `/dashboard-custom/dashboard/${this.dashboard}/detail`
          });
        }
      });
    }
  },

  mounted() {
    if (this.type === "add") {
      this.loading = false;

      for (let item of this.providers) {
        if (item.status != "Deny") {
          this.metricInfo.provider = item.provideruuid;
          this.metricInfo.vendor = item.kind;
          this.metricInfo.region = item.regionID;
          this.metricInfo.cluster = item.cluster;
          break;
        }
      }

      this.metricInfo.dashboardUUID = this.dashboard;
      this.metricInfo.organizationUUID = this.organization;

      this.getMetricsValue();
    } else {
      this.getMetricInfo();
    }
  },

  computed: {
    type() {
      return this.$route.query.type;
    },

    providers() {
      return this.$store.state.app.userInfo.providers;
    },

    organization() {
      return this.$store.state.app.organization;
    },

    dashboard() {
      return this.$route.params.dashboard;
    },

    panel() {
      return this.$route.query.panel;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.promql-query-container {
  margin-bottom: 10px;

  @include flex(space-between);

  .select-provider {
    @include flex(flex-start);
    .select-provider-text {
      font-size: 14px;
    }
    .el-select {
      margin-right: 6px;
    }
  }
}

.metric-wrapper {
  padding: 0 10px;
  // background-color: #f2f5f8;
}

.metric-content-container {
  margin-top: 50px;
  padding: 20px;
}

.metric-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: $header-bg-color;
  @include flex(space-between);
  padding: 8px 20px;
  z-index: 1999;
  box-shadow: $common-shadow;
  color: #fff;

  .back-container {
    font-size: 14px;
    font-weight: 700;
    .svg-icon.back {
      width: 16px;
      height: 16px;
      margin-right: $grid-height;
      cursor: pointer;
    }
  }
}

.metric-chart-wrapper {
  height: 320px;
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: $common-shadow;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;

  .list-title {
    height: 25px;
  }

  .chart-tool {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .metric-chart-container {
    height: calc(100% - 40px);
  }
}

.chart-type-item {
  text-align: center;
  padding: 8px;
  border: 1px solid $border-color;
  cursor: pointer;
  // background-color: #fafbfc;
  border-radius: 4px;
  // @include flex(flex-start);

  .chart-type-item-title {
    @include title(14px);
    margin-bottom: 10px;
  }

  &.active {
    border-color: $color-primary;
    border-width: 1px;
  }

  img {
    // width: 40px;
    // height: 30px;
    // margin-right: 20px;
    height: 40px;
  }

  &:hover {
    border-color: $color-primary;
  }
}

.chart-config-container {
  @include flex();
  margin-bottom: 10px;
}

.config-title {
  @include title(14px);
  margin-bottom: 10px;
}

.chart-tab.el-tabs--card {
  & > .el-tabs__header {
    // border-color: #c1c8cd;

    border-color: transparent;

    .el-tabs__nav {
      border: none;
    }

    .el-tabs__item {
      background-color: #cedae7;
      color: #fff;
      margin-right: 8px;
      border-radius: 4px 4px 0 0;
      padding: 0 30px;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;

      &.is-active {
        background-color: $color-primary;
      }
    }
  }
}

.chart-conf-collapse {
  .el-collapse-item__header {
    font-weight: 600;
  }
}

.generate-promql-container {
  background-color: #fafbfc;
  padding: 10px 4px;
}
</style>
