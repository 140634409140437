import http from "../http";

export const getCategories = () => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/categories`
  });
};

export const createCategory = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/categories`,
    data
  });
};

export const updateCategory = data => {
  return http({
    method: "put",
    url: `/monitor.kubestar.io/categories`,
    data
  });
};

export const deleteCategory = id => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/categories/${id}`
  });
};

export const getDashboardList = category => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/category/${category}/dashboard`
  });
};

export const createDashboard = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/dashboard`,
    data
  });
};

export const updateDashboard = data => {
  return http({
    method: "put",
    url: `/monitor.kubestar.io/dashboard`,
    data
  });
};

export const deleteDashboard = id => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/dashboard/${id}`
  });
};

export const getDashboardDetail = id => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/dashboard/${id}`
  });
};

export const createPanel = (id, data) => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/dashboard/${id}/panels`,
    data
  });
};

export const updatePanel = (id, data) => {
  return http({
    method: "put",
    url: `/monitor.kubestar.io/dashboard/${id}/panels`,
    data
  });
};

export const patchPanel = (id, data) => {
  return http({
    method: "patch",
    url: `/monitor.kubestar.io/dashboard/${id}/panels`,
    data
  });
};

export const getPanel = (dashboardID, panelID) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/dashboard/${dashboardID}/panels/${panelID}`
  });
};

export const deletePanel = (dashboardID, panelID) => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/dashboard/${dashboardID}/panels/${panelID}`
  });
};
